.cw-modal {
  // position: absolute;
  // top: 20px;
  // left: 200px;
  right: auto;
  bottom: auto;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  // margin-right: -50%;
  // transform: translateX(-50%);
  box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1);

  &--close {
    float: right;
    border: 0;
    background: transparent;
    padding: 0;

    .fa-times {
      color: var(--color-primary);
      font-size: 26px;
    }
  }

  &--close_manage_editors {
    float: none;
    border: 0;
    background: transparent;
    padding: 0;
    color: #0d4a6e;
    font-weight: bold;

    .fa-times {
      color: var(--color-primary);
      font-size: 26px;
    }
  }

  &--scrollbar {
    overflow-y: scroll;
    height: 100%;
  }
  &--scrollbar_new_overlay {
    overflow-y: scroll;
    height: 90%;
  }
  &--scrollbar_know_more_message {
    height: 90%;
  }

  .cw-heading--primary,
  p {
    color: var(--color-base);
  }

  p.error {
    color: #721c24;
  }

  &--questions {
    max-width: 600px;
    padding: 15px 20px;
    max-height: 90%;

    .row {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &--changes-description {
    min-width: 400px;
  }

  &--confirm-change {
    width: 500px;
  }

  &--email-preview {
    position: relative;
    width: 80%;
    max-width: 80%;
    height: 90%;
  }

  &--test-email-model {
    overflow-y: auto !important;
    width: 560px;
    max-width: 758px;
    //padding: 15px 20px;
    //max-height: 90%;

    // .row {
    //   padding-top: 12px;
    //   padding-bottom: 12px;
    // }
  }

  &--schedule-email-model {
    width: 600px;
    max-width: 758px;
    padding: 15px 20px;
    max-height: 90%;

    .row {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &--schedule-sms-model {
    width: 610px;
    max-width: 758px;
    padding: 15px 20px;
    max-height: 90%;

    .row {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  &--reject,
  &--approve {
    max-width: 400px;
    padding: 16px;
  }

  &--branddetail {
    max-width: 600px;
    padding: 16px;
  }

  &--qnadetail {
    max-width: 900px;
    padding: 16px;
  }

  &--qnadetail-scrollable {
    max-width: 900px;
    overflow: hidden;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  &--email-checklist-modal {
    max-width: 900px;
    max-height: 90%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  &.apply-styles-modal {
    max-width: 580px;
  }

  &--qnaoverlay {
    width: 60%;
    padding: 16px;
    // height: 100%;
    border: 1px solid #707070;
    overflow: hidden;
  }

  &--branddetail_new_overlay {
    // max-width: 60%;
    // padding: 0px 16px 0 0;
    // width: 70%;
    // padding: 0 0 0 0;
    width: 80%;
  }

  &--banner_message_overlay {
    height: 90vh;
    width: 80%;
    background-color: var(--color-light-grey);
  }

  &--newuser {
    width: 417px;
    padding: 25px;
    max-height: 558px;
  }

  &--newapprover {
    width: 900px;
    padding: 25px;
    max-height: 500px;
  }

  &--formtype {
    max-width: 600px;
    padding: 16px;
  }

  &--competition {
    max-width: 754px;
    padding: 16px;
  }

  &--cancel {
    max-width: 476px;
    padding: 16px;
  }
  &--release-note {
    width: 70%;
    max-width: 900px;
    border: 1px solid var(--color-grey);
    .form-control {
      font-size: var(--text-base-size);
    }
    .btn-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: var(--space-24);
    }
    &__sub-heading a {
      text-decoration: underline;
    }
    .releasedate {
      position: relative;
      &--text {
        position: relative;
        background-color: transparent;
        z-index: 1;
      }
      &--icon {
        position: absolute;
        bottom: 0;
        z-index: 0;
        border-left: 1px solid #dee3e7;
        width: 38px;
        height: 37px;
        right: -3px;
        background-color: #e8ecef;
        padding: 7px 0;
      }
    }
    .tox-tinymce {
      border-radius: 4px;
    }
    .description-text {
      &--counter {
        width: 50%;
        float: right;
        padding: 9px 5px 33px;
        font-size: var(--text-xsmall);
        min-width: 400px;
      }
      &--counter-show {
        float: right;
        font-size: var(--text-xsmall);
      }
      &--counter-label {
        margin-left: 65%;
      }
      .max-char-limit {
        color: var(--color-error);
      }
    }
  }

  &--feedbackform {
    padding: 16px;
    border: 1px solid #707070;
    overflow: auto;
    overflow-y: auto !important;
    max-height: 90vh;
  }

  &--audience-guildline {
    max-width: 900px;
    max-height: 90vh;
    padding: 16px;
  }

  .cw-release-note-content {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
  }
}

.modal-footer {
  border: 0;
  padding: 0;
  justify-content: flex-start;
}

.overlay_row {
  margin: 23px -22px 0 -1px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.column_overlay {
  float: left;
  width: 50%;
  padding: 10px;
  height: 327px;
  /* Should be removed. Only for demonstration */
}

.button_overlay {
  position: relative;
  float: right;
}

.grid-container {
  display: grid;
  grid-gap: 11px;
  // background-color: #2196F3;
  padding: 32px;
  width: 100%;
  // border: 1px solid;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  // padding: 1em;
  // font-size: 30px;
  // text-align: justify;
}

.text-item {
  text-align: left;
  padding: 0 0 0 14px;
}

.text-item-2 {
  text-align: left;
  padding: 0 0 0 0px;
}

.text-item-ul {
  padding: 0 0 0 10px;
}

.item1 {
  grid-column: 1;
  grid-row: 3;
  background-color: #e2e2e2;
  padding: 10px 0px 10px 2px;
}

.item3 {
  grid-column: 1 / span 2;
  grid-row: 4;
  padding: 8px 0 8px 0;
  margin-bottom: -7px;
}

.item4 {
  grid-column: 1;
  grid-row: 5;
  background-color: #e2e2e2;
  padding: 10px 0 10px 0;
}
.item7,
.item2 {
  background-color: #b5d7ed;
  //  text-align: left;
  // padding: 0 0 5px 16px;
  padding: 10px 13px 11px 14px;
  width: 98%;
}

.item8 {
  grid-column: 1 / span 2;
  grid-row: 1;
}

.item5 {
  grid-column: 1 / span 2;
  grid-row: 2;
  margin-bottom: -3px;
}

.item6 {
  grid-column: 1 / span 2;
  grid-row: 6;
}

.heading_overlay {
  font-size: 27px;
  font-weight: 600;
  float: left;
  // padding: 0 0 0 10px;
  // &--h2{
  //   color:#007dbb;
  // }
}

.overlay_top_heading {
  font-size: 32px;
  font-weight: 600;
  float: left;
  //padding: 0 0 0 10px;
  // &--h2{
  //   color:#007dbb;
  // }
}

.horizontal-line {
  border: 0;
  border-top: 2px solid #007dbb;

  &--li {
    border-top: 1px solid #707070;
    margin-left: 4%;
    width: 96%;
  }
}

.horizontal-line-browse {
  border: 0;
  border-top: 2px solid #007dbb;

  &--li {
    border-top: 1px solid #707070;
    width: 96%;
  }
}

.qna-guideline-scroll {
  overflow-y: auto;
  height: 60vh;
  padding-right: 20px;
  //padding-left: 9px;
  &.add-height {
    height: 62vh;
    padding-left: 4px;
    margin-right: 1px;
    margin-top: 2%;
  }
}

.free-height {
  height: unset;
}

.qna-guideline-scroll-browsable {
  overflow-y: auto;
  height: calc(50vh - 20px);
  margin-top: 1%;
  @media (max-width: 1024px) {
    height: calc(37vh - 20px);
  }
  &.add-height {
    height: 62vh;
    padding-left: 4px;
    margin-right: 1px;
    margin-top: 2%;
  }
}

.answer-overlay-scroll-browsable {
  overflow-y: auto;
  height: 54vh;
  // margin-top: 5%;
  @media (max-width: 1024px) {
    height: 51vh;
  }
  &.add-height {
    height: 62vh;
    padding-left: 4px;
    margin-right: 1px;
    margin-top: 2%;
  }
}

.cw-feedback-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--color-background-overlay);

  &--close {
    float: right;
    border: 0;
    background: transparent;
    padding: 0;

    .fa-times {
      color: var(--color-primary);
      font-size: 26px;
    }
  }

  .cw-heading--primary,
  p {
    color: var(--color-base);
  }

  &--content {
    background-color: var(--color-white);
    margin: auto;
    padding: 20px;
    border: 1px solid var(--color-overlay-border);
    width: 712px;
  }
}

.email-overlay-scroll__content {
  overflow-y: auto;
  max-height: 60vh;
  padding-right: 20px;
  //padding-left: 9px;
}

.email-request-more-info-modal-wrapper {
  gap: 10px;

  .requester-switch {
    font-size: var(--text-base-size-sm);
  }
}

.email-request-more-info-icon {
  display: block;
  padding: 5px 8px;
  width: max-content;
  border-radius: 5px;
  align-self: flex-start;
  background: linear-gradient(180deg, #d93eb5 0%, #f51781 100%);
}
.ui.modals {
  .ui.scrolling.modal {
    margin: 0;
  }
  .ui-modal-container {
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .close {
      top: 25px;
      right: 20px;
      color: var(--color-primary);
    }

    .header {
      border-bottom: none;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      min-height: 150px;

      .description {
        color: inherit;
      }
    }

    .actions {
      border-top: none;
      background: transparent;
    }
  }
}
