// Sort Icon
.cw-sort {
  // background: url("./images/sort_both.png") no-repeat right center transparent;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  padding-right: 23px;
  position: relative;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  &:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f0dc';
    position: absolute;
    right: -6px;
    top: 0;
    width: 20px;
    height: 20px;
  }

  &--asc {
    &:before {
      content: '\f0de';
    }
  }

  &--desc {
    &:before {
      content: '\f0dd';
    }
  }
}

.sortable-item {
  .cw-striped-row {
    &:nth-child(odd) {
      background-color: var(--color-table);
      border: none;
    }
  }
}

/* Striped Rows */
.cw-striped-row {
  padding: 14px 13px;

  &:first-child {
    box-shadow: 0 -1px 0 0 #eceeef;
  }

  &:nth-child(odd) {
    background-color: #fff;
    border-bottom: 1px solid var(--color-border-table);
  }

  &:nth-child(even) {
    background-color: #fff;
    border-bottom: 1px solid var(--color-border-table);
  }

  [class^='col-'] {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.cw-striped-row-additional {
  padding: 14px 13px;
  background-color: #fff;
  [class^='col-'] {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

/* Striped Rows */
.cw-striped-profile-row {
  padding: 7px 13px;
  //background-color: #007dbb;
  box-shadow: 0 -1px 0 0 #007dbb;
  &:first-child {
    box-shadow: 0 -1px 0 0 #eceeef;
  }

  &:nth-child(odd) {
    // background-color: #f9f9f9;
    box-shadow: 0 -1px 0 0 #eceeef;
  }

  &:nth-child(even) {
    background-color: #fff;
    box-shadow: 0 -1px 0 0 #eceeef;
  }

  [class^='col-'] {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &--hide_class_0 {
    box-shadow: none !important;
  }
}

/* Striped Rows */
.cw-striped-qna-row {
  padding: 7px 13px;
  margin-left: 15px;
  border-bottom: 1px solid #eceeef;
  [class^='col-'] {
    display: flex;
    //justify-content: center;
    flex-direction: column;
  }
}

.cw-striped-email-brief-row {
  padding: 7px 13px;
  border-bottom: 1px solid #eceeef;
  [class^='col-'] {
    display: flex;
    //justify-content: center;
    flex-direction: column;
  }
}

.cw-qna-lable {
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.collpased-striped-row {
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:nth-child(odd) {
    background-color: #fff;
  }
}

// Collapse
.collapse-h {
  display: block;
}

// Hidden Element
.cw-hidden {
  display: none;
}

.cw-new {
  position: absolute;
  right: 50px;
  top: 8px;
  min-width: 50px;
}

.cw-new-question-dropdown {
  position: absolute;
  right: 50px;
  top: 47%;
  min-width: 50px;
}

// Sub Categories
.cw-subCategory {
  position: relative;
  padding-top: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -12px;
    width: 2px;
    height: calc(100% - 9px);
    border-left: 2px dashed var(--color-primary);
  }

  .cw-new {
    top: 40px;
  }
}

.cw-promotigosubCategory {
  position: relative;
  padding-top: 20px;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: -22px;
    width: 2px;
    height: calc(100% - 9px);
    border-left: 2px dashed var(--color-primary);
  }
}

.cw-question-answer-dotted-line {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 8px;
    width: 2px;
    height: calc(100% - 9px);
    border-left: 2px dashed var(--color-primary);
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.text-decoration-underline {
  text-decoration: underline;
}

.cw-consent-modal-text {
  font-size: initial;
}

.cw-consent-modal-list {
  font-size: x-small;
}

.cw-icons {
  display: inline-block;
  margin: 10px;
}

.cw-font-smaller {
  font-weight: 100;
  font-size: 12px;
}

.fa-cog,
.fa-edit,
.fa-trash-alt {
  color: var(--color-primary);
  cursor: pointer;
}

.cw-save-to-draft {
  display: block;
  // width: 15%;
}

button.cw-save-to-draft {
  border: none;
  background: none;
  display: block;
  color: var(--color-primary);
  padding: 0;
  text-align: left;
}

.cw-cancel-edit {
  display: block;
}

button.cw-cancel-edit {
  border: none;
  background: none;
  display: block;
  color: var(--color-primary);
  padding: 0;
  text-align: left;
}

.cw-dropdown-optionbox {
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  width: 200px;
}

.cw-span-label {
  color: var(--color-primary);

  &:after {
    content: '\A';
  }
}

.tagText {
  color: var(--color-primary);
  display: block;
  font-size: 12px;
}

.tagValue {
  display: block;
  font-size: 14px;
}

.cw-dropdown-button {
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  text-align: left;
  background-color: #fff;
  position: relative;
  width: 165px;
  height: 40px;
}

.cw-dropdown-button .fas {
  font-weight: 900;
  top: 0px;
  position: absolute;
  right: -6px;
  top: 10px;
  width: 20px;
  height: 20px;
}

.cw-external-link {
  height: 24px;
  width: 112px;
  color: var(--color-primary);
  font-size: 14px;
  line-height: 24px;
  margin-left: 14px;
}

.cw-breadcrum {
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 600;
}

.cw-dashboard-filters {
  background-color: var(--color-background-pill);
  border: 1px solid var(--color-background-pill);
  border-radius: 15px;
  display: flex;
  min-width: 73px;
  box-sizing: border-box;
  margin: 2px;
  height: 23px;
  float: left;
}

.cw-dashboard-filters.clearall {
  background-color: #ffffff;
  border: none;
  padding: 0px 3px;
  min-width: unset;
}

.cw-dashboard-filters-label {
  color: rgb(51, 51, 51);
  font-size: var(--font-size-xsmall);
  line-height: var(--line-height-xxsmall);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 3px 3px 3px 6px;
}

.cw-dashboard-filters-label.clearalllabel {
  color: var(--color-primary);
  padding: 3px;
  cursor: pointer;
}

.cw-dashboard-filters-remove {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  border-radius: 2px;
}

.cw-rt-dashboard-filter div[class$='-menu'] > div > div div {
  position: unset !important;
}
.cw-rt-dashboard-filter div[class$='-menu'] > div > div {
  height: auto !important;
}
.cw-rt-dashboard-filter div[class$='-menu'] > div {
  height: auto !important;
  // max-height: 100%;
}
.cw-promotigo-container {
  display: flex;
  width: 100%;
}

.cw-promotigo-child {
  flex: 1;
  margin-right: 44px;
  width: 50%;
}

.promotigocompetition {
  table {
    tbody th,
    tbody td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.btn-group-vertical > button {
  margin-bottom: 10px;
  border-radius: 4px;
}

.fa-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.cw-question-text {
  word-break: break-all;
  width: 80%;
}

.cw-question-icon {
  width: 95px;
}

.cw-word-wrap {
  word-wrap: break-word;
  -ms-word-wrap: break-word;
}

.branddetails_items_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.branddetails_items_list li {
  padding-left: 1em;
  text-indent: -0.7em;
}

.branddetails_items_list li::before {
  content: '• ';
  color: var(--color-primary);
}

.item_guide_ul {
  margin: 0 0 0;
  padding: 0;
  counter-reset: item;
}
.items_guide_list ul {
  list-style: none;
  padding-left: 10px;
  margin: 0;
}
.item_guide_ul .items_guide_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-increment: item;
}
.item_guide_ul > .items_guide_list {
  padding-left: 20px;
  position: relative;
}

.item_guide_ul .items_guide_list::before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) '.';
  position: absolute;
  left: 0;
}

.items_guide_list .item_guide_li {
  padding-left: 0;
  position: relative;
}

.items_guide_list .item_guide_li::before {
  content: '• ';
  position: absolute;
  left: -10px;
  display: block;
}

.campaign-edited {
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 14px 13px;
}

.cw-striped-row.sorting-child-container {
  background-color: #ffffff;
  margin-bottom: -2%;
  margin-top: 3%;
}

.status_badge_reposition_masterdata {
  float: right;
  width: 16%;
  @media (max-width: 1200px) {
    width: auto;
  }
}

.status_badge_reposition_overview {
  float: right;
  width: 16%;
  @media (max-width: 1200px) {
    width: auto;
  }
}

.status_badge_reposition_approval {
  float: right;
  width: 16%;
  margin-right: 0%;
}

.draft_button_section {
  float: right;
  margin: -7% 0 0 -1%;
}

.qna_guide_ul {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
  font-size: var(--text-small);
}
.qna_guide_list ul {
  list-style: none;
  padding-left: 10px;
  margin: 0;
}
.qna_guide_ul .qna_guide_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-increment: item;
}
.qna_guide_ul > .qna_guide_list {
  padding-left: 20px;
  position: relative;
}

.qna_guide_ul .qna_guide_list::before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  text-align: right;
  content: counter(item) '.';
  position: absolute;
  left: 0;
}

.qna_guide_list .item_guide_li {
  padding-left: 0;
  position: relative;
}

.qna_guide_list .item_guide_li::before {
  content: '• ';
  position: absolute;
  left: -10px;
  display: block;
}

.cw-question-answer-dotted-line {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 8px;
    width: 2px;
    height: calc(100% - 9px);
    border-left: 2px dashed var(--color-primary);
  }
}

.cw-qna-new {
  position: absolute;
  right: 50px;
  top: 74px;
  min-width: 50px;
}
.cw-dashboard {
  &__filters {
    &--advance {
      .show {
        display: block;
        .col-sm-3 {
          padding-left: 0;
        }
      }
      .hide {
        display: none;
      }
    }
    &--advance-toggle {
      background-repeat: no-repeat;
      background-size: 23px;
      background-color: transparent;
      border: none;
      font-size: var(--text-base-size);
      color: var(--color-primary);
      padding: 10px 0px 12px;
      i {
        padding: 0 10px;
      }
      i:first-child {
        padding-left: 0;
      }
      .toggle-text {
        min-width: 200px;
      }
    }
  }
  &__filter_email_with {
    max-width: 19%;
    .css-151xaom-placeholder {
      color: #808080 !important;
    }
  }
}
.menuaccordion {
  border: 1px solid var(--color-grey);
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  padding: 1px;
  bottom: 0;
  right: 0;
  width: 30%;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  position: fixed !important;
  font-size: var(--text-small);
  color: var(--color-base);
  .ui.accordion:not(.styled) .title ~ .content:not(.ui) {
    padding: 0;
  }
  .ui.accordion .title:not(.ui) {
    padding: 10px 0 10px 20px;
    text-transform: uppercase;
  }
  .menuaccordion__items {
    background-color: var(--color-white);
    padding: 0;
    &:hover {
      background-color: var(--color-white);
    }
    &:nth-child(odd) {
      background-color: var(--color-white);
    }
    &--item {
      padding: 0;
      button {
        width: 100%;
        padding: 0;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        text-align: left;
        justify-content: space-between;
        border-bottom: 2px solid var(--color-menu-grey);
        .menu-item-icon {
          width: 25px;
          height: 25px;
          color: var(--color-base);
          font-size: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .menu-item-img {
          width: 25px;
          height: 25px;
        }
        .menu-item-img-container {
          display: flex;
          align-items: center;
          width: 45px;
          overflow: hidden;
          padding: 10px;
          background-color: var(--color-menu-grey);
        }
        .menu-item-text {
          padding: 10px 0;
          padding-left: 10px;
          padding-right: 10px;
          text-transform: lowercase;
          flex: 1;
          @media (max-width: 1024px) {
            max-width: 150px;
          }
        }

        .menu-item-text-right {
          padding: 10px 0;
          padding-right: 30px;
          text-align: right;
          float: right;
        }

        .menu-item-text::first-letter {
          text-transform: uppercase;
        }

        .menu-item-text-keep-case {
          text-transform: none;
        }
      }
    }
    &--title {
      border-bottom: 2px solid var(--color-menu-grey);
      background-color: var(--color-white);
      padding-left: 10px;
      > i {
        float: right;
        padding-right: 28px;
      }
    }

    .disabled {
      padding: 10px 20px;
      color: gray;
    }
  }
  &__heading {
    padding: 4px 10px;
    border-bottom: 1px solid var(--color-grey);
    display: flex;
    flex-direction: row;
    &--title {
      width: 100%;
      padding: 3px 0 3px 10px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &--icon {
      width: 20px;
      cursor: pointer;
      padding-right: 29px;
    }
  }
  .menuaccordion__item {
    border: 1px solid transparent;
    &:hover {
      background-color: var(--color-menu-grey);
    }
  }
}

.cw-sticky-section {
  padding-right: 0px;
  .sidebar-item {
    top: 0;
    width: 100%;
    margin-left: 27px;
    /* Position the items */
    // &:nth-child(2) { top: 25%; }
    // &:nth-child(3) { top: 50%; }
    // &:nth-child(4) { top: 75%; }

    .cia-alert {
      position: fixed;
      right: 27px;
      width: 30%;
      top: 200px;
      margin-top: var(--space-43);
    }
  }

  .make-me-sticky {
    position: -webkit-sticky;
    position: sticky;
    padding: 0;
  }
}
.cw-section {
  &--title-wrap,
  &--title-lang {
    padding-bottom: 20px;
    margin-bottom: 0;
  }
}
@keyframes glowing {
  0% {
    background-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
  }
  50% {
    //background-color: var(--color-border-btn);
    box-shadow: 0 0 20px var(--color-border-btn);
  }
  100% {
    background-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
  }
}
.cw-add-fields {
  margin: 0;
  right: 0;
  height: 40px;
  position: fixed;
  width: 200px;
  border-radius: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  float: right;
  display: flex;
  align-items: center;

  img {
    width: 39px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid white;
  }
}

.cw-add-fields--edit {
  top: 159px;
}

@keyframes glowing {
  0% {
    background-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
  }
  50% {
    //background-color: var(--color-border-btn);
    box-shadow: 0 0 20px var(--color-border-btn);
  }
  100% {
    background-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
  }
}

.field {
  display: flex;
  margin-bottom: 17px;

  .saas-accordion-close {
    top: 6px;
  }

  .saas_new_master_logo {
    top: 8.5px;
  }

  .card-header-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .card {
    flex: 1;
    .card-header {
      position: relative;
      display: flex;
      flex: 1;
      padding: 0.6rem 1rem;
      font-size: 14px;
      border: 1px solid #9d9d9d;
      border-radius: 5px;
      background-color: transparent;
    }
  }

  .saas-accordion-heading {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    max-width: unset;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .form-group {
    min-height: calc(1.5em + 0.75rem + 2px);
  }
}

.iconfirm {
  margin-top: 33px;
  margin-bottom: 30px;
}

.btn-box-ml-auto {
  margin-left: auto;
  max-width: 85%;
}

.clickable {
  cursor: pointer;
}

.custom-menu-acc {
  position: relative !important;
  width: 100%;
  height: 600px;
}

.qna-stats {
  height: 600px;
}

.report-heading-title {
  overflow: hidden;
  white-space: nowrap;
  width: 20%;
  font-weight: 700;
}

.report-heading-line {
  width: 100%;
  height: 3px;
  background-color: var(--color-grey-line);
}

.faq-stats-list {
  overflow-y: auto;
  height: 600px;

  &.add-height {
    height: 62vh;
    padding-left: 4px;
    margin-right: 1px;
    margin-top: 2%;
  }
}

.faq-stats-line {
  border: 0;
  border-top: 2px solid #007dbb;

  &--li {
    border-top: 1px solid #707070;
    width: 99%;
  }
}

.dash-stats {
  width: 220px;

  .dash-stats-line {
    width: 100%;
    height: 3px;
    //background-color: var(--color-primary);
    background-color: #2b2b2b;
    margin-bottom: 12px;
  }

  .dash-stats-title {
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: 400;
  }

  .dash-stats-count {
    font-size: 28px;
    font-weight: 700;
    color: var(--color-grey-number);
    margin-bottom: 10px;
  }

  .dash-stats-percent {
    font-size: 18px;
    font-weight: 400;
  }
}

.temp-height {
  height: 800px;
}
.large-text {
  font-family: var(--font-primary);
  font-size: var(--text-large);
  line-height: var(--line-height-large);
}

.light-text {
  font-size: var(--text-xsmall);
  line-height: var(--line-height-xsmall-spaced);
  color: var(--color-text-light);
}

.small-text {
  font-size: var(--text-xsmall);
  line-height: var(--line-height-xsmall-spaced);
}

.dark-text {
  font-size: var(--text-small);
  line-height: var(--line-height-small);
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1024px) {
    font-size: 8px;
  }
}
.btn-responsive {
  @media (max-width: 1024px) {
    font-size: 10px !important;
  }
}

.campaign-status-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  width: 176px;
  border-radius: 10px;
  line-height: var(--line-height-xsmall-spaced);
}

.inline-button {
  display: inline;
  border: none;
  background: transparent;
  color: var(--color-primary);
  padding: 0;
}

.external-link-icon {
  margin-left: 4px;
  height: 16px;
  width: 16px;
  display: inline;
}

.external-link-wrapper {
  display: inline-flex;
}

.guideline {
  font-size: var(--text-small);
}

.guideline-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 2%;
  position: relative;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--space-5);

    .title {
      color: var(--color-primary);
      font-size: var(--font-size-h3);
      font-weight: var(--font-weight-semi-bold);
    }

    .guideline-wrapper-close-icon {
      &:after {
        content: '\f057';
        font-weight: 600;
        font-family: 'Font Awesome 5 Free';
        cursor: pointer;
        color: var(--color-primary);
        font-style: normal;
      }
      &.disabled:after {
        color: #cccccc;
      }
    }
  }

  &--body {
    min-height: 0;
    overflow-y: auto;
    padding-right: 3px;
  }
}

.guideline-close-button {
  float: right;
  border-radius: 8px;
}

.master-data-notice {
  margin-top: var(--space-5);

  & > span {
    font-size: var(--text-xsmall);
    line-height: var(--line-height-small);
    color: var(--color-error);
  }
}

.cw-qna-faq-link {
  font-size: 14px;
  &--ab-height {
    margin-top: 5px;
  }
}

.rejection-list {
  &--row {
    border-bottom: 1px solid var(--color-cancelled);
  }

  &--column {
    padding: 15px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
.notification {
  background-color: var(--color-orange-2);
  padding: 15px 0;
  height: 98px;
  overflow: hidden;
  &--icon {
    i.warning {
      padding: 0;
      margin: 0px;
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
  &--close {
    i.close {
      padding: 0;
      margin: 0px;
      position: absolute;
      left: 0;
      top: 14px;
    }
  }
  a[target='_blank']:after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }
}
//override height in case of notification
.cw-header-wrapper.notification-visible.cw-header-wrapper--shrink {
  height: 154px;
}

.acquisition-text {
  font-weight: 600;
  color: var(--color-primary);
}

.error-text {
  color: #df1642;
}
.masterdata-complete-message-text {
  width: 100%;
}

.min-height-60 {
  min-height: 60vh;
}

.text-size-small {
  font-size: var(--text-small) !important;
}

.sms-link-wrapper {
  display: inline;
}

.sms-external-link-icon {
  margin-left: 2px;
  height: 16px;
  width: 16px;
  display: inline;
}

.primary-text {
  color: var(--color-primary);
}

.absolute-label {
  display: inline-block;
  position: absolute;
  z-index: 10;
}

.blue-color {
  color: var(--color-primary);
}

.yellow-color {
  color: var(--color-email-warning);
}

.text-link-right {
  color: var(--color-primary);
  text-decoration: underline;
}
.richtext-preview-only {
  background-color: var(--color-background);
  padding: 5px;
  min-height: 100px;
  padding: 15px;
  font-size: 14px;
}
